/*
*
*	provides validation using 4imprint styling rules and adds accessibility attributes
*	the html document needs to have a heading with a "formHeading" class to insert the error summary after

    PARAMS:
        formId - id of the container to look for inputs to validate
        callBack - function to execute if validation passes
        e - event object
    USAGE:
        var registerForm = new forms4i();
        document.getElementById('btnSubmit').addEventListener("click", function (e) {
            e.preventDefault(); --> This may not be necessary depending on the form
            registerForm.formValidation(document.getElementById("RegistrationForm"), formSuccess(), e, formFailure());
        });
    UPDATES VS 2020 VERSION:
        - Refactored formValidation
        - Added additional password complexity validation - includes support for multiple errors on password fields
        - Added invalidateField which allows displaying of errors from server side validation or other sources

*/

var forms4i = function () {
    "use strict";

    var closest = function (el, fn) {
        return el && (fn(el) ? el : closest(el.parentNode, fn));
    },

        bindPasswordButtons = function () {
            var togglePass = document.querySelectorAll(".toggle-password");
            if (togglePass.length > 0) {
                for (var i = 0; i < togglePass.length; i++) {
                	console.log(togglePass[i]);
                    togglePass[i].removeEventListener("click", toggleThis);
                    togglePass[i].addEventListener("click", toggleThis);
                }
            }
        },
        
        toggleThis = function (e) {
            e.preventDefault();
            var el = e.currentTarget;
            var inputId = el.getAttribute("toggle");
            if (document.getElementById(inputId).getAttribute("type") === "password") {
                document.getElementById(inputId).setAttribute("type", "text");
                el.setAttribute("aria-pressed", "true");
            } else {
                document.getElementById(inputId).setAttribute("type", "password");
                el.setAttribute("aria-pressed", "false");
            }
        },
        
        clearValidation = function () {
            if (document.getElementById("ErrorBox")) {
                document.getElementById("ErrorBox").parentNode.removeChild(document.getElementById("ErrorBox"));
            }
            var invalidFields = [].slice.call(document.getElementsByClassName("input-validation-error"));
            invalidFields.forEach(function (el, index, array) {
                el.classList.remove("input-validation-error");
                el.setAttribute("aria-invalid", "false");
                if(el.parentNode.querySelector(".errorMessage")){
                	el.parentNode.querySelector(".errorMessage").innerHTML = "";
                	el.parentNode.querySelector(".errorMessage").classList.add("hide");
                }
            });
        },

        isCardNumber = function (cardnum, rules, validLengths) {
            var cardnumber = cardnum;
            for (var n = 0; n < validLengths.length; n++)
                if (cardnumber.toString().length == validLengths[n]) {
                    for (var m = 0; m < rules.length; m++) {
                        var headdigit = cardnumber.substring(0, rules[m].toString().length);
                        if (headdigit == rules[m])
                            return true;
                    }
                    return false;
                }
            return false;
        },

        isValidCardNumber = function (el) {
            var cardnum = el.value;
            if (cardnum.indexOf("****") != -1) { // saved card already, in edit mode so no need to recheck validity
                return true;
            }
            switch (cardnum.substring(0, 1)) {
                case "3":
                    return isCardNumber(cardnum, [34, 37], [15]);
                case "4":
                    return isCardNumber(cardnum, [4], [13, 16]);
                case "2":
                case "5":
                    return isCardNumber(cardnum, [51, 52, 53, 54, 55, 22, 23, 24, 25, 26, 27], [16]);
                case "6":
                    return isCardNumber(cardnum, [6011], [16]);
                default:
                    return false;
            }
        },

        fadeCCImgs = function (cardNumElem, btnElem, savedNameElem) {
            switch (cardNumElem.value.substring(0, 1)) {
                case "3": //ae
                    if (document.getElementById('aeccimg')) {
                        if (document.getElementById('aeccimg')) {
                            document.getElementById('aeccimg').setAttribute("alt", "American Express");
                        }
                        if (document.getElementById('mcccimg')) {
                            document.getElementById('mcccimg').setAttribute("alt", "Mastercard");
                        }
                        if (document.getElementById('visaccimg')) {
                            document.getElementById('visaccimg').setAttribute("alt", "Visa");
                        }
                        if (document.getElementById('discccimg')) {
                            document.getElementById('discccimg').setAttribute("alt", "Discover");
                        }
                        cardAccepted("American Express", cardNumElem, btnElem, savedNameElem);
                    } else {
                        cardNotAccepted("American Express", cardNumElem, btnElem);
                    }
                    break;
                case "4": //visa
                    if (document.getElementById('visaccimg')) {
                        if (document.getElementById('aeccimg')) {
                            document.getElementById('aeccimg').setAttribute("alt", "American Express");
                        }
                        if (document.getElementById('mcccimg')) {
                            document.getElementById('mcccimg').setAttribute("alt", "Mastercard");
                        }
                        if (document.getElementById('visaccimg')) {
                            document.getElementById('visaccimg').setAttribute("alt", "Visa");
                        }
                        if (document.getElementById('discccimg')) {
                            document.getElementById('discccimg').setAttribute("alt", "Discover");
                        }
                        cardAccepted("Visa", cardNumElem, btnElem, savedNameElem);
                    } else {
                        cardNotAccepted("Visa", cardNumElem, btnElem);
                    }
                    break;
                case "2":
                case "5": //mc
                    if (document.getElementById('mcccimg')) {
                        if (document.getElementById('aeccimg')) {
                            document.getElementById('aeccimg').setAttribute("alt", "American Express");
                        }
                        if (document.getElementById('mcccimg')) {
                            document.getElementById('mcccimg').setAttribute("alt", "Mastercard");
                        }
                        if (document.getElementById('visaccimg')) {
                            document.getElementById('visaccimg').setAttribute("alt", "Visa");
                        }
                        if (document.getElementById('discccimg')) {
                            document.getElementById('discccimg').setAttribute("alt", "Discover");
                        }
                        cardAccepted("MasterCard", cardNumElem, btnElem, savedNameElem);
                    } else {
                        cardNotAccepted("MasterCard", cardNumElem, btnElem);
                    }
                    break;
                case "6": //discover
                    if (document.getElementById('discccimg')) {
                        if (document.getElementById('aeccimg')) {
                            document.getElementById('aeccimg').setAttribute("alt", "American Express");
                        }
                        if (document.getElementById('mcccimg')) {
                            document.getElementById('mcccimg').setAttribute("alt", "Mastercard");
                        }
                        if (document.getElementById('visaccimg')) {
                            document.getElementById('visaccimg').setAttribute("alt", "Visa");
                        }
                        if (document.getElementById('discccimg')) {
                            document.getElementById('discccimg').setAttribute("alt", "Discover");
                        }
                        cardAccepted("Discover", cardNumElem, btnElem, savedNameElem);
                    } else {
                        cardNotAccepted("Discover", cardNumElem, btnElem);
                    }
                    break;
                case "":
                    if (document.getElementById('aeccimg')) {
                        document.getElementById('aeccimg').setAttribute("alt", "American Express");
                    }
                    if (document.getElementById('mcccimg')) {
                        document.getElementById('mcccimg').setAttribute("alt", "Mastercard");
                    }
                    if (document.getElementById('visaccimg')) {
                        document.getElementById('visaccimg').setAttribute("alt", "Visa");
                    }
                    if (document.getElementById('discccimg')) {
                        document.getElementById('discccimg').setAttribute("alt", "Discover");
                    }
                    cardAccepted(null, cardNumElem, btnElem, savedNameElem);
                    break;
                default:
                    if (document.getElementById('aeccimg')) {
                        document.getElementById('aeccimg').setAttribute("alt", "American Express");
                    }
                    if (document.getElementById('mcccimg')) {
                        document.getElementById('mcccimg').setAttribute("alt", "Mastercard");
                    }
                    if (document.getElementById('visaccimg')) {
                        document.getElementById('visaccimg').setAttribute("alt", "Visa");
                    }
                    if (document.getElementById('discccimg')) {
                        document.getElementById('discccimg').setAttribute("alt", "Discover");
                    }
                    cardNotAccepted(null, cardNumElem, btnElem);
                    break;
            }
        },

        verifyName = function (name) {
            const digits = name.match(/\d/g);

            if (digits && digits.length > 12) {
                for (var i = 0; i < digits.length - 4; i++) {
                    name = name.replace(/\d/, 'x');
                }
            }

            return name;
        },

        cardNotAccepted = function (cardName, cardNumElem, btnElem) {
            //btnElem.disabled = true;
            cardNumElem.classList.add("input-validation-error");
            cardNumElem.setAttribute("aria-invalid", "true");
            var errorMsg;
            if (cardName) {
                errorMsg = cardNumElem.parentNode.querySelector(".errorMessage");
                errorMsg.textContent = "We do not accept " + cardName + ".";
            } else {
                errorMsg = cardNumElem.parentNode.querySelector(".errorMessage");
                errorMsg.textContent = "This does not appear to be a valid card number.";
            }
            cardNumElem.parentNode.querySelector(".errorMessage").classList.remove("hide");
        },

        cardAccepted = function (cardName, cardNumElem, btnElem, savedNameElem) {
            //btnElem.removeAttribute("disabled");
            cardNumElem.classList.remove("input-validation-error");
            cardNumElem.setAttribute("aria-invalid", "false");
            cardNumElem.parentNode.querySelector(".errorMessage").textContent = "";
            cardNumElem.parentNode.querySelector(".errorMessage").classList.add("hide");

            if (savedNameElem && !savedNameElem.value && cardName) {
                savedNameElem.value = cardName;
                return;
            }
            if (savedNameElem) {
                var savename = savedNameElem.value;
            }
            if (!cardName && savename && (savename === "American Express" || savename === "Visa" || savename === "Discover" || savename === "MasterCard")) {
                savedNameElem.value = '';
            }
        },

        showSaveCardName = function (isSavedElem, savedNameElem, isDefaultElem) {
            if (isSavedElem.checked == true) {
                if (savedNameElem.value === '.') {
                    savedNameElem.value = '';
                }
                isSavedElem.setAttribute("aria-expanded", "true");
                savedNameElem.style.display = "block";
                savedNameElem.classList.add("txtReq");
                savedNameElem.parentNode.querySelector("label").textContent = "Name this Card*";
                savedNameElem.parentNode.querySelector("label").style.display = "block";
                if (isDefaultElem) {
                    isDefaultElem.style.display = "block";
                    isDefaultElem.nextElementSibling.style.display = "block";
                }
            } else {
                isSavedElem.setAttribute("aria-expanded", "false");
                savedNameElem.style.display = "none";
                savedNameElem.value = "";
                savedNameElem.classList.remove("txtReq");
                savedNameElem.parentNode.querySelector("label").textContent = "Name this Card";
                savedNameElem.parentNode.querySelector("label").style.display = "none";
                savedNameElem.parentNode.querySelector("span").classList.add("hide");
                savedNameElem.classList.remove("input-validation-error");
                if (isDefaultElem) {
                    isDefaultElem.style.display = "none";
                    isDefaultElem.nextElementSibling.style.display = "none";
                    isDefaultElem.checked = false;
                }
            }
        },

        /*
        maskCCNumber = function (ccMaskElem, cardNumElem) {
            var ccMask = ccMaskElem.value;
            if (cardNumElem && ccMask != "") {
                VMasker(cardNumElem).maskPattern(ccMask);
            }
        },
        */

        maskCCNumber = function (ccMaskElem, cardNumElem) {
            var ccMask = ccMaskElem.value;
            if (cardNumElem && ccMask != "") {
                function inputHandler(masks, event) {
                    var input = event.target;
                    var digits = input.value.substring(0, 2);
                    var maskIndex = digits === '34' || digits === '37' ? 1 : 0;
                    VMasker(input).unMask();
                    VMasker(input).maskPattern(masks[maskIndex]);
                }
                var ccMaskArr = ccMask.split(",");
                VMasker(cardNumElem).maskPattern(ccMaskArr[0]);
                cardNumElem.addEventListener('input', inputHandler.bind(undefined, ccMaskArr), false);
            }
        },

        maskCVVNumber = function (cvvMaskElem, cardCVVElem) {
            var cvvMask = cvvMaskElem.value;
            if (cardCVVElem && cvvMask != "") {
                VMasker(cardCVVElem).maskPattern(cvvMask);
            }
        },

        unmaskCCNumber = function (cardNumElem) {
            VMasker(cardNumElem).unMask();
        },

        getPaymentTypeID = function (creditCardIds, cardNumber, currentPTID) {
            return getCreditCardId(creditCardIds, getCreditCardName(cardNumber), currentPTID);
        },

        getCreditCardName = function (cardNumber) {
            var fcDig = cardNumber.substring(0, 1);
            switch (fcDig) {
                case "3":
                    return "American Express";
                case "4":
                    return "Visa";
                case "2":
                case "5":
                    return "MasterCard";
                case "6":
                    return "Discover";
                default:
                    return "Credit Card";
            }
        },

        getCreditCardId = function (creditCardIds, creditCardName, currentPTID) {
            // credit card name needs to match name in paymenttypeid enum
            var index = creditCardIds.map(function (e) {
                return e.Name;
            }).indexOf(creditCardName.replace(/\s+/g, ""));
            return index >= 0 ? creditCardIds[index].Id : currentPTID;
        },

        maskPhoneNumber = function (phoneMaskElem, phoneNumElem) {
            var phoneNumMask = phoneMaskElem.value;
            if (phoneNumElem && phoneNumMask != "") {
                VMasker(phoneNumElem).maskPattern(phoneNumMask);
            }
        },

        formatAsErrorLine = function (el, errorMsg) {
            var line = '<li><a href="###ELEMENTID##" tabindex="0" role="link" class="errorItem">##ERRORMESSAGE##</a></li>';
            return line.replace(new RegExp("##ELEMENTID##", 'g'), el.id).replace("##ERRORMESSAGE##", errorMsg);
        },

        addErrorPanel = function (formId, errorLines) {

            // Get or create form heading
            var formHeading = formId.getElementsByClassName("formHeading")[0];
            if (formHeading == null) {
                formId.insertAdjacentHTML('afterbegin', "<div class='formHeading'></div>");
                formHeading = formId.getElementsByClassName("formHeading")[0];
            }

            // Check if error panel exists
            var errorMsgBox  = formId.querySelector("#ErrorMsgBox");
            
            // If exists, append to contents
            if(errorMsgBox) {
                errorMsgBox.innerHTML += errorLines;
            } else{
                // Else create
                var panel = '<div id="ErrorBox" class="ErrorMsgPanel" role="alert" aria-atomic="true">' 
                + '<h3 id="ErrorTitle" class="validationHeading">Please correct the following errors and resubmit:</h3>'
                +'<ul id="ErrorMsgBox">##ERRORLINES##</ul></div>';
                formHeading.insertAdjacentHTML('afterend', panel.replace("##ERRORLINES##", errorLines));
            }
            

            // reorder error lines if there are required radio buttons that would put them out of order.
            var requiredSelectionElems = [].slice.call(formId.querySelectorAll('.rdbReq'));
            if (requiredSelectionElems.length > 0) {
                var formElements = [].slice.call(document.querySelectorAll("form input, form textarea, form select, div input, div textarea, div select"));
                var errorListLines = [].slice.call(document.getElementById("ErrorMsgBox").getElementsByTagName('li'));
                var newErrorListLines = new Array();
                errorListLines.forEach(function (elem, ind, arr) {
                    formElements.forEach(function (el, index, array) {
                        if (el.id == elem.firstChild.hash.replace("#", "")) {
                            newErrorListLines[formElements.indexOf(el)] = elem;
                        }
                    });
                });
                var filtered = newErrorListLines.filter(function (el) {
                    return el != null;
                });
                var errorMsgBoxList = document.getElementById("ErrorMsgBox");
                while (errorMsgBoxList.firstChild) {
                    errorMsgBoxList.removeChild(errorMsgBoxList.firstChild);
                }
                filtered.forEach((el) => {
                    errorMsgBoxList.appendChild(el);
                });
            }

            try {
                formHeading.scrollIntoView();
                setTimeout(()=>{
                	formId.querySelector(".ErrorMsgPanel").querySelector("a").focus();
                }, 200);
            } catch (err) {
                //selector.getElementsByClassName("ErrorMsgPanel")[0].getElementsByTagName("li")[0].getElementsByTagName("a")[0].focus();
            }

            // focus on appropriate input when error link in list is clicked
            var errorListItems = [].slice.call(document.getElementsByClassName("errorItem"));
            errorListItems.forEach(function (el, index, array) {
                el.addEventListener("click", function (e) {
                    e.preventDefault();
                    var errorElem = document.getElementById(el.getAttribute("href").replace("#", ""));
                    if (errorElem) {
	                    errorElem.scrollIntoView({
	                        behavior: "smooth", block: "center"
	                    });
	                    errorElem.focus();
                    }
                });
            });

        },

        validateRequiredField = function (el) {
            // Initialize result
            let result = {
                valid: true,
                errorMsg: ""
            };

            // Evaluate attributes
            if (el.hasAttribute("data-val-postalcodevalidation-required")) {
                if (el.getAttribute("data-val-postalcodevalidation-required").toLowerCase() === "true") {
                    result.valid = false;
                    result.errorMsg = el.hasAttribute("data-val-postalcodevalidation") ? el.getAttribute("data-val-postalcodevalidation") : el.title;
                }
            } else if (el.hasAttribute("data-val-statevalidation-required")) {
                if (el.getAttribute("data-val-statevalidation-required").toLowerCase() === "true") {
                    result.valid = false;
                    result.errorMsg = el.hasAttribute("data-val-statevalidation") ? el.getAttribute("data-val-statevalidation") : el.title;
                }
            } else if (el.hasAttribute("data-val-companynamevalidation-required")) {
                if (el.getAttribute("data-val-companynamevalidation-required").toLowerCase() === "true") {
                    result.valid = false;
                    result.errorMsg = el.hasAttribute("data-val-companynamevalidation") ? el.getAttribute("data-val-companynamevalidation") : el.title;
                }
            } else {
                result.valid = false;
                result.errorMsg = el.hasAttribute("data-val-required") ? el.getAttribute("data-val-required") : el.title;
            }

            return result;
        },

        validateSelectionField = function (groupName) {

            let result = {
                valid: true,
                errorLine: ""
            }

            // Get all elements in group
            var groupElems = [].slice.call(document.getElementsByName(groupName));

            // Mark invalid if none are checked
            if (!groupElems.some(e => e.checked)) {
                result.valid = false;
            }

            // Get target fields
            var lastElem = groupElems[groupElems.length - 1];
            var fieldset = closest(lastElem, function (elem) {
                return elem.tagName.toLowerCase() === "fieldset";
            });
            if (!result.valid) {
                // Add error indicators               
                lastElem.classList.add("input-validation-error");
                lastElem.setAttribute("aria-invalid", "true");

                // Show error message element
                fieldset.querySelector(".errorMessage").classList.remove("hide"); // testing this to see if we have our radio buttons in two columns if this will still work

                // Add error message
                var firstElem = groupElems[0];
                var errorText = fieldset.querySelector(".errorMessage").innerHTML;
                result.errorLine = formatAsErrorLine(firstElem, errorText);
            } else {
                // Reset errors
                lastElem.classList.remove("input-validation-error");
                if (fieldset.getElementsByClassName("errorMessage")[0]) { // testing this to see if we have our radio buttons in two columns if this will still work
                    fieldset.getElementsByClassName("errorMessage")[0].classList.add("hide");
                }
                lastElem.setAttribute("aria-invalid", "false");
                lastElem.setAttribute("tabindex", "-1");
            }

            return result;
        },

        validateField = function (el) {
            let result = {
                valid: true,
                errorList: []
            };

            // remove any mask that may be on the value  //TODO
            //var value = el.data().mask ? el.cleanVal() : el.value;
            var value = el.value;
            var innerHTML = el.innerHTML;

            // check if there is a value
            if (value != undefined && value.trim() === "") {
                var reqResult = validateRequiredField(el);
                if (!reqResult.valid) {
                    result.valid = reqResult.valid;
                    result.errorList.push(reqResult.errorMsg);
                }
            }
            // hack for details page 
            else if (innerHTML != undefined && innerHTML.toLowerCase().indexOf("select") != -1 && el.getElementsByClassName("colorOptionText")[0]) {
                if (el.getElementsByClassName("txtToValidate")[0]) {
                    //validateRequired(el.getElementsByClassName("txtToValidate")[0]);
                } else {
                    var reqResult = validateRequiredField(el);
                    if (!reqResult.valid) {
                        result.valid = reqResult.valid;
                        result.errorList.push(reqResult.errorMsg);
                    }
                }
            } else if ((el.pattern !== "" && value.search(el.pattern) < 0) || (el.hasAttribute("data-val-regex-pattern") && value.search(el.getAttribute("data-val-regex-pattern")) < 0)) {
                // check if there is a reg expression
                result.valid = false;
                result.errorList.push(el.hasAttribute("data-val-regex") ? el.getAttribute("data-val-regex") : el.title);
            } else if (el.hasAttribute("data-val-regexnotmatch-pattern") && value.toLowerCase().search(el.getAttribute("data-val-regexnotmatch-pattern")) >= 0) {
                // check if there is a reg expression
                result.valid = false;
                result.errorList.push(el.hasAttribute("data-val-regexnotmatch") ? el.getAttribute("data-val-regexnotmatch") : el.title);
            } else if (el.hasAttribute("data-val-length-max") && value.trim().length > parseInt(el.getAttribute("data-val-length-max"))) {
                // check if there is a max length
                result.valid = false;
                result.errorList.push(el.hasAttribute("data-val-length") ? el.getAttribute("data-val-length") : el.title);
            } else if (el.hasAttribute("data-val-length-min") && value.trim().length < parseInt(el.getAttribute("data-val-length-min"))) {
                // check if there is a min length
                result.valid = false;
                result.errorList.push(el.hasAttribute("data-val-length") ? el.getAttribute("data-val-length") : el.title);
            } else if (el.hasAttribute("data-val-equalto-other") && document.getElementById(el.getAttribute("data-val-equalto-other")) !== null && document.getElementById(el.getAttribute("data-val-equalto-other")).value.trim() !== value.trim()) {
                // check if there is a comparison
                result.valid = false;
                result.errorList.push(el.hasAttribute("data-val-equalto") ? el.getAttribute("data-val-equalto") : el.title);
            } else if (el.hasAttribute("data-validate-expiry") && el.getAttribute("data-validate-expiry") && document.getElementById(el.getAttribute("data-validate-expiry")) !== null) {
                // check if credit card expiry date
                var year = document.getElementById(el.getAttribute("data-validate-expiry")).value;
                var today = new Date();
                if (year === today.getFullYear().toString().slice(-2) && parseInt(value) < today.getMonth() + 1) {
                    result.valid = false;
                    result.errorList.push("Please enter a valid expiration date.");
                }
            } else if ((el.hasAttribute("data-validate-size-too-many") && el.getAttribute("data-validate-size-too-many").trim().toLowerCase() === 'true')) {
                //makes sure that an select does not have Select as its selected option
                result.valid = false;
                result.errorList.push("Too many of this size/gender are selected. Please select a different option for box #" + el.id.split('_')[1]);

            } else if ((el.hasAttribute("data-validate-value-cant-be-select") && el.getAttribute("data-validate-value-cant-be-select").trim().toLowerCase() === 'true')) {
                //makes sure that an select does not have Select as its selected option
                if (el.value.toLowerCase() === "select") {
                    result.valid = false;
                    result.errorList.push(el.title);
                }

            } else if ((el.id == "PaymentMethod_CardNumber" || el.id == "CardNumber") && !isValidCardNumber(el)) {
                result.valid = false;
                result.errorList.push(el.title);
            } else if (el.id === 'allocationQuantity' || el.id === 'sampleItemAllocationQuantity') {
                try {
                    let selectedObj = el.id === 'allocationQuantity' ? document.getElementById('selectItemGrouping') : document.getElementById('selectSampleItem');
                    const maxQuantity = parseInt(selectedObj[selectedObj.selectedIndex].getAttribute('data-quantity-remaining'));
                    const quantity = parseInt(el.value);
                    if (quantity > maxQuantity) {
                        result.valid = false;
                        result.errorList.push('Allocated quantity exceeds available quantity of ' + maxQuantity);
                    }
                }
                catch (e) {
                    console.log(e);
                }
            }

            // Password complexity validation
            // Not an else - need to evaluate in addition to length
            if (el.hasAttribute("data-val-password-complexity-threshold")) {
                var threshold = parseInt(el.getAttribute("data-val-password-complexity-threshold"));
                var criteriaCount = 0;
                var errList = [];
                // Check lower
                if (el.value.toUpperCase() != el.value) {
                    criteriaCount++;
                } else {
                    errList.push("Please enter a password with at least one lowercase letter.");
                }
                // Check upper
                if (el.value.toLowerCase() != el.value) {
                    criteriaCount++;
                } else {
                    errList.push("Please enter a password with at least one uppercase letter.");
                }
                // Check number
                if (/\d/.test(el.value)) {
                    criteriaCount++;
                } else {
                    errList.push("Please enter a password with at least one number.");
                }

                // Check special char
                if (/[!@#^&*()+_,.{}?~]/.test(el.value)) {
                    criteriaCount++;
                } else {
                    errList.push("Please enter a password with at least one symbol [!@#^&*()+_,.{}?~]");
                }

                // Check if valid
                if (threshold > criteriaCount) {
                    result.valid = false;
                    result.errorList.push(...errList);
                }
            }

            return result;
        },

        formValidation = function (formId, callBackSuccess, e, callBackFailure) {
           
            var validate = function (selector) {
                var formValid = true;
                var errorLines = "",
                    invalidFields = [].slice.call(selector.getElementsByClassName("input-validation-error")),
                    fieldsToValidate = [],
                    allInputFields = [].slice.call(selector.querySelectorAll("input, select, textarea")),
                    requiredSelectionElems = [].slice.call(selector.querySelectorAll('.rdbReq'));


		  // my account issues - contact form
		  let textReqFields = selector.getElementsByClassName("txtReq");
		  for (let i = 0; i < textReqFields.length; i++){
		  	let el = textReqFields[i];
		  	if((el.type == undefined && el.classList.contains("prodColorOptions") && !el.parentNode.parentNode.parentNode.classList.contains("hide")) 
		  		|| (el.type != undefined && el.type.toLowerCase() != "hidden")) {
		  			fieldsToValidate.push(el);
	  			}
		  }
		  
                // Capture additional fields to validate
                allInputFields.forEach(function (el, index, array) {
                    if (!el.disabled && !el.classList.contains("txtReq") && el.type.toLowerCase() != "submit" && el.type.toLowerCase() != "hidden") {
                        if (el.tagName.toLowerCase() == "input" || el.tagName.toLowerCase() == "textarea") {
                            if (el.type.toLowerCase() != "checkbox" && el.type.toLowerCase() != "radio" && el.value.trim() != "") {
                                fieldsToValidate.push(el);
                            }
                        } else if (el.tagName.toLowerCase() == "select" && (el.value != "0" && el.value != "")) {
                            fieldsToValidate.push(el);
                        } else if (el.tagName.toLowerCase() == "div" && (el.innerHTML == "" || el.innerHTML.indexOf("Select ") != -1)) {
                            fieldsToValidate.push(el);
                        }
                    }
                });

                // Reset error indicators on previously invalid fields
                invalidFields.forEach((el) => {
                    el.classList.remove("input-validation-error");
                });

                if (document.getElementById("ErrorBox")) {
                    document.getElementById("ErrorBox").parentNode.removeChild(document.getElementById("ErrorBox")); // fix for IE11
                }

                var errorMessages = [].slice.call(selector.getElementsByClassName("errorMessage"));
                errorMessages.forEach((el) => el.classList.add("hide"));

                // Validate selection fields (radio buttons and checkboxes)
                // Get selection groups
                var selectionGroups = requiredSelectionElems
                    .map(e => e.name)
                    .filter((name, index, arr) => arr.indexOf(name) == index);

                // Validate each selection group
                //Check if we have any selection groups
                if (Array.isArray(selectionGroups) && selectionGroups.length) {
                     selectionGroups.forEach((groupName) => {
                        var result = validateSelectionField(groupName)
                        if (!result.valid) {
                            // if any fields are invalid, set form to invalid
                            formValid = false;
                            errorLines += result.errorLine;
                        }
                    });
                }
               
               

                // Validate remaining fields
                fieldsToValidate.forEach((el) => {
                    // Evaluate each field
                    var result = validateField(el);

                    if (!result.valid) {
                        // if any fields are invalid, set form to invalid
                        formValid = false;

                        // Build and show inline errors for invalid field
                        // Append field errors to list as we are gathering errors on all fields
                        errorLines += buildAndShowInlineErrors(el, result.errorList);

                    } else {
                        // If field is valid, reset error indicators
                        el.classList.remove("input-validation-error");
                        if (el.parentNode.getElementsByClassName("errorMessage")[0]) {
                            el.parentNode.getElementsByClassName("errorMessage")[0].classList.add("hide");
                        }
                        el.removeAttribute("aria-invalid");
                        el.removeAttribute("tabindex");
                    }
                });

                // if form is invalid
                if (!formValid) {
                    e && e.preventDefault && e.preventDefault();

                    addErrorPanel(selector, errorLines);

                    if (typeof callBackFailure === "function") {
                    //console.log(callBackFailure);
                        callBackFailure();
                        return;
                    }
                    return formValid;
                } else if (typeof callBackSuccess === "function") {
                    callBackSuccess();
                }

            };

            validate(formId);
        },

        serverValidation = function (formId) {
            var validate = function (selector) {
                try {
                    var items = [].slice.call(selector.getElementsByTagName("li")),
                        element,
                        anchorTag,
                        start = 0,
                        end = 0,
                        html = "";

                    items.forEach(function (el, index, array) {
                        html = el.innerHTML;
                        if (el.getElementsByTagName("a")[0]) {
                            anchorTag = el.getElementsByTagName("a")[0];
                        }
                        start = html.indexOf("document.getElementById");

                        if (start >= 0) {
                            end = html.indexOf(")", start);

                            if (end > start) {
                                element = eval(html.substring(start, end + 1));
                                if (element) {
                                    element.className += (element.className ? " input-validation-error" : "input-validation-error");
                                    element.setAttribute("aria-invalid", "true");
                                    element.removeAttribute("tabindex");
                                    element.parentNode.getElementsByClassName("errorMessage")[0].classList.remove("hide");
                                    if (anchorTag) {
                                        element.parentNode.getElementsByClassName("errorMessage")[0].innerHTML = anchorTag.innerHTML;
                                    }
                                }
                            }
                        }
                    });
                } catch (e) {
                    console.log(e.message);
                }
            };
            validate(formId);
        },

        invalidateField = function (formEl, targetEl, errorList) {

            let errorLines = buildAndShowInlineErrors(targetEl, errorList);
            addErrorPanel(formEl, errorLines);
        },

        buildAndShowInlineErrors = function (el, errorList) {
            let errorLines = "";
            el.classList.add("input-validation-error");
            el.setAttribute("aria-invalid", "true");
            el.parentNode.querySelector(".errorMessage").classList.remove("hide");

            el.parentNode.querySelector(".errorMessage").innerHTML = "";

            errorList.forEach((err) => {
                el.parentNode.querySelector(".errorMessage").innerHTML += err + '<br />';
                errorLines += formatAsErrorLine(el, err);
            });

            return errorLines;
        }

    bindPasswordButtons();

    return {
        formValidation: formValidation,
        serverValidation: serverValidation,
        BindPasswordButtons: bindPasswordButtons,
        FadeCCImgs: fadeCCImgs,
        MaskCCNumber: maskCCNumber,
        MaskCVVNumber: maskCVVNumber,
        UnmaskCCNumber: unmaskCCNumber,
        MaskPhoneNumber: maskPhoneNumber,
        GetPaymentTypeID: getPaymentTypeID,
        GetCreditCardName: getCreditCardName,
        GetCreditCardId: getCreditCardId,
        ShowSaveCardName: showSaveCardName,
        ClearValidation: clearValidation,
        VerifyName: verifyName,
        InvalidateField: invalidateField
    };
}